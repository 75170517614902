// Moved to _async_fonts
//+font_include(Pusia-Medium,Pusia-Medium,Pusia-Medium)
//+font_include(Pusia-Regular,Pusia-Regular,Pusia-Regular)

@import "../../../views/blocks/popup/popup";
@import "../../../views/blocks/popup/popup_video";
@import "../../../views/blocks/popup/popup_thank_you";

//libs
@import "../libs/datepicker";

.datepicker {
	position: fixed;
	z-index: 999999999;
	//top: 50%!important
	//left: 50%!important
	//transform: translate(-50%, -50%)!important
	//.datepicker--pointer
 }	//	display: none

.margin_right {
	margin-right: 40px; }
.link_width {
	width: 98px; }

footer {
	background: #7A6569;
	padding-bottom: 17px;
	padding-top: 25px;

	.input {
		height: 32px; }

	.btn.btn_footer {
		padding: 0 10px!important;
		height: 32px!important;
		min-height: 32px!important;
		font-weight: lighter;
		font-family: $p-r, sans-serif; }


	.container {
		flex-direction: row;
		flex-wrap: wrap; }
	.top_line {
		display: flex;
		flex-direction: column;
		width: 100%;

		.footer {
			&_col_logo {
				//margin-bottom: 25px
				display: flex;
				//order: 1
				justify-content: center;
				.footer_col_item {
					width: 85px;
					height: 85px;
					background-position: center;
					background-repeat: no-repeat;
					background-size: 100% 100%;
					margin-right: 10px;
					//&:first-child
					//	width: 100px
					&:last-child {
						margin-right: 0; }
					svg {
						width: 100%;
						height: 100%; }
					@media(min-width: 1200px) {
						width: 140px;
						height: 140px;
						margin-right: 15px;
						//&:first-child
 } } }						//	width: 170px
			&_col_title {
				color: #fff;
				font-size: 23px;
				line-height: 28px;
				margin-bottom: 14px;
				font-family: $p-m, sans-serif; }

			&_col_menu {
				display: flex;
				flex-direction: column;
				align-items: center;
				order: 5; }
			&_col_menu_list {
				display: flex;
				flex-direction: column;
				flex-wrap: wrap;
				//height: 85px
				height: 101px;
				width: 100%;
				align-items: flex-start;
				li {
					margin-bottom: 11px; } }
			&_col_menu_link {
				font-size: 13px;
				line-height: 17px;
				color: #fff;
				font-family: $p-r,sans-serif; }
			&_col_singUp {
				display: flex;
				flex-direction: column;
				align-items: center;
				order: 6; }
			&_col_singUp_group {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				width: 100%;
				.form-group {
					width: 48%; }

				.input-container {
					width: 100%; }
				.w-mid {
					width: 100%; }
				.btn {
					min-width: 48%;
 } }					//min-width: 50%


			&_col_faq_groupBtn {
				display: flex;
				order: 4;
				flex-direction: row;
				justify-content: center;
				width: 100%;
				//justify-content: space-between
				padding-top: 20px;
				.footer_btn {
					cursor: pointer;
					text-align: center;
					//padding: 11px 20px
					padding: 11px;
					width: 48%;
					margin-bottom: 30px;
					background: transparent;
					border-radius: 20px;
					display: flex;
					justify-content: center;
					align-items: center;
					border: 1px solid #FFFFFF;
					box-sizing: border-box;
					color: #fff;
					font: {
						//size: 19px
						size: 14px;
						family: $p-m, sans-serif; }
					transition: all 300ms ease-in-out;
					&:hover {
						transition: all 300ms ease-in-out;
						border: 1px solid #e54360;
						color: #e54360; } } } } }

	.column_right {
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		align-items: center;
		width: 100%; }
	.policy_list {
		display: flex;
		width: 100%;
		align-items: center;
		@media(min-width: 320px) and (max-width: 767px) {
			flex-wrap: wrap;
			align-items: center;
			justify-content: center; } }
	.bottom_line {
		display: flex;
		flex-direction: column-reverse;
		width: 100%;
		padding-top: 20px; }

	.column_left {
		display: flex;
		flex-direction: column;
		align-items: center; }
	.policy_list {
		a, span {
			width: fit-content;
			font: {
				size: 14px;
				family: $p-m; }
			color: #fff;
			text-align: center;
			border-bottom: none;
			transition: border-bottom.45s;
			margin-bottom: 7px;
			border-bottom: 1px solid transparent;
			&:hover {
				border-bottom: 1px solid #fff;
				transition: border-bottom.45s;
 }				//border-bottom: 1px solid transparent
			&:last-child {
				margin-bottom: 0; }
			@media(min-width: 320px) and (max-width: 767px) {
				font-size: 13px;
				margin-right: 10px;
				&:last-child {
					margin-bottom: 7px;
					margin-right: 0; } }
			@media(min-width: 768px) {
				white-space: nowrap; } } }

	.copyright {
		font: {
			size: 14px;
			family: $p-m; }
		color: #fff;
		margin-top: 20px; }

	.verified {
		margin-bottom: 10px;
		color: #ACA1A3;
		font: {
			size: 15px;
			family: $p-m, sans-serif; } }
	.visa {
		margin-bottom: 10px; } }

.btn_top {
	position: fixed;
	right: 50%;
	//bottom: 15px
	bottom: -10px;
	transform: translate(50%, -50%);
	//width: 25px
	//height: 25px
	width: 40px;
	height: 40px;
	display: none;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	overflow: hidden;
	border-radius: 50%;
	z-index: 9999;
	svg {
		width: 50%; }
	&.bottom {
		bottom: 0;
 }		//transform: translate(50%,10%)
	&.show {
		display: flex!important; }
	&.white {
		svg {
			path {
				fill: white; } } }
	svg {
		//width: 100%
		//animation: btn_top 2.4s linear infinite
		//position: absolute
		//transform: translate3d(0, -30%, 0);
		path {
			fill: #7a6569; } } }

//@keyframes btn_top
//	0%
//		transform: translate3d(0, -30%, 0);
//
//	50%
//		transform: translate3d(0, 30%, 0);
//
//	100%
//		transform: translate3d(0, -30%, 0);


@media(min-width: 365px) {
	footer {
		.top_line {
			.footer {
				&_col_singUp_group {
					//.form-group
					//	width: auto
					.btn {
						min-width: 50%; } } } } } }
@media(min-width: 500px) {
	.margin_right {
		margin-right: 0; }
	.link_width {
		width: auto; }
	.footer_col_menu_list {
		height: 85px; } }
@media (min-width: 768px) {
	footer {
		.bottom_line {
			padding-top: 0;
			width: 100%;
			flex-direction: row;
			justify-content: space-between; }
		.policy_list, .column_right, .column_left {
			flex-direction: row; }
		.column_right, .policy_list {
			width: auto; }
		.policy_list {
			a, span {
				//margin-right: 24px
				margin-right: 13px;
				font-size: 12px;
				text-align: right;
				margin-bottom: 0;
				&:last-child {
					margin-right: 0; } } }

		.copyright {
			margin-left: 10px;
			font-size: 12px;
			margin-top: 0; }

		.verified {
			font-size: 12px;
			margin-bottom: 0;
			margin-right: 10px;
			svg {
				margin-right: 10px; } }

		.visa {
			margin-bottom: 0; } } }




@media (min-width: 960px) {
	footer {
		.top_line {
			flex-direction: row;
			align-items: center;
			margin-bottom: 25px;
			//justify-content: space-between
			.footer_col_wrap {
				display: flex;
				align-items: center;
				width: 100%;
				justify-content: center; }
			.footer {

				&_col_logo {
					margin-right: 25px; }
				&_col_singUp_group {
					flex-direction: column;
					.form-group {
						width: 100%; }
					.btn {
						min-width: 164px; } }


				&_col_menu {
					margin-right: 41px;
					width: auto; }

				&_col_faq_groupBtn {
					flex-direction: column;
					.footer_btn {
						//margin-bottom: 0
						margin-left: 12px;
						width: 230px;
						max-width: 230px;
						margin-bottom: 15px;
						&:first-child {
							margin-left: 10px; } } }
				&_col_logo {
					//margin-right: 30px
					margin-bottom: 0; }
				//order: 1
				//justify-content: center
				//&_col_contacts
				//	align-items: flex-start
				//	order: 2
				//	margin-right: 34px
				//	margin-bottom: 0
				//&_col_contacts_list
				//	align-items: flex-start

				&_col_menu {
					order: 3;
					//margin-right: 133px
					align-items: flex-start;
					//width: 100%
					width: auto;
					margin-right: auto;
					.footer_col_title {
						padding-left: 14px; } }
				&_col_menu_list {
					height: 85px;
					align-items: flex-start;
					li {
						padding-right: 14px;
						padding-left: 14px; } }

				&_col_singUp {
					order: 4;
					flex-direction: column;
					align-items: flex-start;
					.form-group {
						//margin-right: 10px
						margin-bottom: 15px; } }

				&_col_singUp_group {
					flex-direction: column; }
				//	.w-mid
				//		width: 184px
				//
				//	.input-container
				//		margin-bottom: 0

				//&_col_registration_groupBtn
				//	order: 5

				&_col_faq_groupBtn {
					order: 6;
					width: auto; } } }
		//.footer_btn
		//	padding: 11px
		//	margin-bottom: 0
		//	margin-right: 11px
		//	width: auto

		.policy_list {
			a, span {
				margin-right: 10px;
				font-size: 14px; } }
		.copyright {
			font-size: 15px;
			margin-left: 20px; }
		.verified {
			margin-right: 30px;
			font-size: 15px; }
		.top_line {
			.footer {
				&_col_logo {
					margin-right: 41px; }
				&_col_singUp_group {
					flex-direction: column;
					.btn {
						min-width: 164px; } }


				&_col_menu {
					margin-right: 41px;
					width: auto; }

				&_col_faq_groupBtn {
					flex-direction: row;
					.footer_btn {
						margin-bottom: 0;
						margin-left: 12px;
						width: 230px;
						max-width: 230px;
						&:first-child {
							margin-left: 10px; } } } } } } }
@media(min-width: 1130px) {
	.btn_top {
		right: 25px;
		bottom: 0;
		background-color: #7a6569;
		svg path {
				fill: white; }
		&.white {
			background-color: white;
			svg {
				path {
					fill: #7a6569; } } } } }
@media(min-width: 1130px) {
	footer {
		.top_line {
			.footer {
				&_col_singUp_group {
					flex-direction: row;
					.form-group {
						.input-container {
							margin-bottom: 0; } }
					.label_active {
						transform: translateY(-140%); } }
				&_col_singUp {
					.form-group {
						margin-bottom: 0;
						margin-right: 10px;
						width: 184px; } } }

			.footer_col_wrap {
				align-items: flex-end; } } } }


footer {
	#form-login,#form-reg,#form-restore {
		.input {
			height: 42px; } } }

.cookies-popup {
	background-color: #fff;
	position: fixed;
	border: 1px solid #7A6569;
	box-sizing: border-box;
	border-radius: 20px;
	left: 50%;
	bottom: 6px;
	z-index: 994;
	transform: translateX(-50%);
	width: 94%;
	height: 160px;
	padding: 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	.btn {
		min-height: 14px!important;
		position: absolute;
		min-width: 50px!important;
		padding: 4px;
		bottom: 10px;
 } }		//right: 150px

.cookies-close {
	width: 14px;
	height: 14px;
	cursor: pointer; }
.cookies-text {
	font-family: $p-r,sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 15px;
	line-height: 19px;
	text-align: center;
	color: #7A6569;
	padding-bottom: 15px;
	p {
		display: inline; }
	a {
		color: #7A6569;
		text-decoration: underline; } }
@media screen and (min-width: 540px) {
	.cookies-popup {
		width: 356px;
		left: 6px;
		transform: translateX(0); } }

.btn_footer {
	font-size: 16px!important; }
.footer_col_title {
	position: relative;
	display: flex;
	align-items: center; }
.footer_col_singUp {
	.footer_col_title {
		text-align: center;
		flex-direction: column;
		@media(min-width: 996px) {
			flex-direction: row; } } }
.Contact_Info_Icon_Slider_Item {
	width: 20px;
	height: 20px;
	margin-right: 10px;
	&:last-child {
		margin-right: 0; } }

.Contact_Info_Phone_Container {
	display: flex;
	justify-content: center;
	padding-left: 10px; }

.footer_col_wrap {
	@media(min-width: 320px) and (max-width: 767px) {
		margin-top: 20px; }
	@media(min-width: 960px) {
		display: flex;
		flex-direction: column; }
	@media(min-width: 996px) {
		align-items: end!important; } }
.footer_btn_wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	@media(min-width: 960px) {
		order: 7; }
	@media(min-width: 996px) {
		flex-direction: row;
		width: 100%;
		justify-content: end; }
	.footer_btn_channel {
		display: flex;
		background: #E54360;
		border: 1px solid #E54360;
		box-sizing: border-box;
		border-radius: 35px;
		padding: 10px 20px;
		font-family: $p-r, sans-serif;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		line-height: 18px;
		text-transform: uppercase;
		color: #FFFFFF;
		span {
			margin-left: 7px; } }
	.footer_btn {
		cursor: pointer;
		text-align: center;
		padding: 11px;
		min-width: 48%;
		max-width: 230px;
		margin-bottom: 15px;
		background: transparent;
		border-radius: 20px;
		display: flex;
		justify-content: center;
		align-items: center;
		border: 1px solid #FFFFFF;
		box-sizing: border-box;
		color: #fff;
		font: {
			size: 14px;
			family: $p-m, sans-serif; }
		transition: all 300ms ease-in-out;
		&:hover {
			transition: all 20ms ease-in-out;
			border: 1px solid #e54360;
			color: #e54360; }
		@media(min-width: 996px) {
			margin-bottom: 0%;
			margin-right: 10px;
			min-width: 230px; } } }

.policy_list {
    span {
        cursor: pointer; } }

.articles-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    transform: translateY(100%);
    transition: transform 450ms ease-in-out;

    &.articles-popup-open {
        z-index: 999;
        transform: translateY(0); } }

.article__item {
    text-align: center;
    a {
        font-family: $p-r, sans-serif;
        color: #FFFFFF;
        font-size: 10px;
        font-weight: 500;
        line-height: 12px; } }

.articles-popup {
    position: absolute;
    max-width: 870px;
    width: 100%;
    background-color: #776669;
    padding: 44px 26px;
    display: grid;
    column-gap: 11px;
    row-gap: 15px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media (min-width: 320px) and (max-width: 767px) {
        grid-template-columns: 1fr;
        max-height: calc(100% - 200px);
        overflow: auto; }

    @media (min-width: 768px) {
        grid-template-columns: repeat(auto-fit, minmax(195px, 1fr));
        max-height: 475px;
        border-radius: 50px; } }
