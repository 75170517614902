$popup-z-index: 1000000;

.Button_Close {
	width: 30px;
	height: 30px;
	right: 15px;
	top: 15px;
	position: absolute;
	background-repeat: no-repeat;
	background-size: contain;

	z-index: $popup-z-index + 5;
	svg {
		fill: #ec788d; } }

.Hider {
	display: none;
	position: fixed;
	//height: 100%
	//width: 100%
	height: 0;
	width: 0;
	//left: 0
	//top: 0
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	z-index: $popup-z-index - 10;
	//opacity: 0.7
	background-color: rgba(62, 68, 74, 0.7);
	transition: width.45s ease-in-out, height.45s ease-in-out;
	&.Video_Popup {
		pointer-events: none; }
	&.active {
		//pointer-events: auto
		width: 100%;
		height: 101%;
		transition: width.45s ease-in-out, height.45s ease-in-out; } }

.Popup {
	&.Active {
		display: block; }
	.Popup_Svg {
		display: flex;
		justify-content: center;
		width: 100%;
		margin-bottom: 22px; }
	/* Popup_footer Popup */
	&.Video_Popup {
		border: 2px solid #ec788d;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		z-index: $popup-z-index;
		visibility: hidden;
		opacity: 0;
		background-color: transparent;
		color: white;
		transform: translate(-50%,-50%);
		left: 50%;
		min-width: 300px;
		//max-width: 996px
		height: calc(100vh / 2);
		width: calc(100vw - 20px);
		//width: 88%
		top: 50%;
		overflow: visible;
		&.Visible { // Notification show
            visibility: visible;
            opacity: 1; }
		.video_container {
			width: 100%;
			height: 100%;
			video, iframe {
				width: 100%;
				height: 100%;
				position: relative;
				z-index: 2;
 }				//height: calc(100vh - 104px)
			.video_loader {
				position: absolute;
				left: 50%;
				width: 100px;
				height: 100px;
				top: 50%;
				transform: translate(-50%, -50%);
				svg {
					width: 100%;
					height: 100%;
					stroke: #ec788d; } } }
		.Button_Close {
			border-radius: 100%;
			background: #fff;
			right: -13px;
			top: -14px; } } }

@media (orientation: landscape) {
	.Popup {
		&.Video_Popup {
			height: calc(100vh - 100px); } } }
@media (min-width: 768px) {
	.Popup {
		&.Video_Popup {
			height: calc(100vh - 100px);
			width: calc(100vw - 100px); } } }
@media screen and (min-width: 992px) {
	.Button_Close {
		//height: 1vw
		//width: 1vw
		width: 30px;
		height: 30px;
		transform: scale(1);
		transition: transform 300ms ease-in-out;
		cursor: pointer;
		&:hover {
			transform: scale(1.2);
			transition: transform 300ms ease-in-out; } } }

/* Notification Popup END */
