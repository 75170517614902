* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-ms-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-tap-highlight-color: transparent;
	outline: none; }


html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

button {
  outline: none; }
.slick-slide {
  outline: none!important; }
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }
a {
  text-decoration: none; }
blockquote, q {
  quotes: none; }

blockquote {
  &:before, &:after {
    content: '';
    content: none; } }

q {
  &:before, &:after {
    content: '';
    content: none; } }

table {
  border-collapse: collapse;
  border-spacing: 0; }


::-webkit-scrollbar-track:hover {
	border-left: solid 1px #7a6569;
	background-color: #7a6569; }

::-webkit-scrollbar-track {
	border-width: 0;
	background-color: #7a6569; }

::-webkit-scrollbar-thumb {
	background-color: #e44360; }

::-webkit-scrollbar {
	width: 8px; }


.overflow-none-on-scroll-y::-webkit-scrollbar {
	width: 0; }
.overflow-none-on-scroll-y {
	-ms-overflow-style: none;
	overflow: -moz-scrollbars-none;
	overflow-y: scroll; }

.modal-enter {
	opacity: 0;
	transform: scale(0.9); }
.modal-enter-active {
	opacity: 1;
	transform: translateX(0);
	transition: opacity 300ms, transform 300ms; }
.modal-exit {
	opacity: 1; }
.modal-exit-active {
	opacity: 0;
	transform: scale(0.9);
	transition: opacity 300ms, transform 300ms; }

strong {
	font-weight: bold; }
