/* ======================================================================================
   @mixin 						Styles for standart form									=
   @mixin =====================================================================================
   @mixin  If you want to change form design, copy this sass, change styles 					=
   @mixin  (do not change classnames) and include new sass on page with form					=
   @mixin ===================================================================================== */

.form_margin-top {
	margin-top: 38px; }

.form-group { // parent of input container
	position: relative; }

.form-group-btn { // parent of submit button
	position: relative;
	margin-top: 4.75vw;
	padding-top: 11px;
	display: flex;
	justify-content: space-between;
	flex-direction: column; }


.input-container { // child of form group and parent of input and label
	position: relative;
	//select
	//	&:focus
	//		& + .input-group-addon
	//			transform: rotate(0deg)
	//			transition: 0.3s
	.select_arrow {
		position: absolute;
		top: 50%;
		right: 10px;
		z-index: -1;
		transition: 0.3s;
		transform: translate(-50%, -50%) rotate(-90deg); }

	.select_arrow_click {
		transition: 0.3s;
		transform: translate(-50%, -50%) rotate(0deg); } }

.label { // input label
	position: absolute;
	font-family: $p-r,sans-serif;
	font-weight: 500;
	color: #808080;
	font-size: 11px;
	top: 50%;
	left: 16px;
	transform: translate(0, -50%);
	transition: transform 300ms ease-in, font-size 300ms ease-in;
	z-index: 600;
	&_active {
		font-family: $p-r,sans-serif;
		color: #808080;
		font-size: 9px;
		transform: translate(0px,-180%);
		//transition: transform 300ms ease-in, font-size 300ms ease-in
		transition: transform 300ms ease-in; } }

//input size


.input_error { // error text
	position: absolute;
	right: 0;
	bottom: -12px;
	font-size: 9px;
	color: red;
	opacity: 0;
	visibility: hidden;
	will-change: opacity;
	transition: opacity 300ms ease-in-out, visibility 300ms ease-in-out; }
.error_active .input_error { // error text, when input group have error class
	font-size: 13px;
	opacity: 1;
	visibility: visible;
	will-change: opacity;
	transition: opacity 300ms ease-in-out, visibility 300ms ease-in-out; }

.general-error { // Error for whole form. Can be anywhere in form. Shows server validation error
	position: absolute;
	right: 0;
	left: 0;
	bottom: -6px;
	font-size: 11px;
	color: red;
	opacity: 0;
	visibility: hidden;
	will-change: opacity;
	transition: opacity 300ms ease-in-out, visibility 300ms ease-in-out;
	&.active {
		opacity: 1;
		visibility: visible;
		will-change: opacity;
		transition: opacity 300ms ease-in-out, visibility 300ms ease-in-out; } }



.btn { // default button
	padding: 10px 10px;
	min-width: 100%;
	text-align: center;
	font-weight: bold;
	font-family: $p-r,sans-serif;
	font-size: 11px;
	color: #fff;
	background-color: #fff;
	border-radius: 50px;
	border: 1px solid #fff;
	cursor: pointer;
	transition: background-color 400ms ease-in-out, border-color 400ms ease-in-out; }

// -------------------------
.select-label {
	//position: relative
	//left: 0!important
	//top: -10px!important
	position: absolute;
	left: 50%!important;
	top: -10px!important;
	transform: translate(-50%, -50%)!important;
	width: 100%; }

select:active, select:focus {
	outline: none; }

.select_pay {
	background-color: transparent;
	width: 100%;
	height: 40px;
	border-radius: 30px;
	border: 1px solid #b3b3b3;
	font-size: 11px;
	font-family: $p-r,sans-serif;
	font-weight: 300;
	padding-left: 16px;
	padding-right: 10px;
	transition: border 0.3s;
	-webkit-appearance: none;
	-moz-appearance: none;
	-o-appearance: none;
	appearance: none;
	option {
		font-size: 11px;
		font-family: $p-r,sans-serif;
		font-weight: 300; } }

.input { // standart input
	width: 100%;
	height: 40px;
	border-radius: 30px;
	border: 1px solid #fff;
	font-size: 11px;
	font-family: $p-r,sans-serif;
	font-weight: 300;
	padding-left: 16px;
	padding-right: 10px;
	outline: none;
	transition: border 0.3s; }
.input_transparent {
	border: 1px solid #fff;
	background-color: transparent;
	width: 88vw;
	height: 45px;
	font-style: normal;
	font-weight: normal;
	font-size: 15px;
	line-height: 19px;
	color: #fff; }
.input_standart {
	border: 1px solid #B3B3B3;
	background-color: #fff;
	height: 45px;
	font-style: normal;
	font-weight: normal;
	font-size: 15px;
	line-height: 19px;
	color: #4D4D4D; }
.input_transparent-margin {
	margin-left: 0px;
	margin-right: 0px;
	margin-top: 24px;
	margin-bottom: 24px; }
.label_color {
	color: #fff; }
.label_color_4D4D4D {
	color: #4D4D4D; }
//&::placeholder
.error_active .input { // input, when input group have error class
	border-color: red;
	transition: border 0.3s; }

.form_flex_center {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column; }
.form_flex_column {
	display: flex;
	flex-direction: column; }
.input_transparent-margin {
	margin-left: 0px;
	margin-right: 0px;
	margin-top: 14px;
	margin-bottom: 14px; }

@media screen and (min-width: 547px) {
	.input { // standart input
		height: 40px; } }

@media screen and (min-width: 996px) {
	.input_transparent {
		width: 260px; }
	.input_transparent-margin {
		margin-left: 14px;
		margin-right: 14px;
		margin-top: 0px;
		margin-bottom: 0px; }
	.form_flex_center {
		flex-direction: row; }
	.form-group-btn { // parent of submit button
		flex-direction: row;
		padding-top: 0; }

	.input_error { // error text
		font-size: 0.8vw; }

	.general-error { // Error for whole form. Can be anywhere in form. Shows server validation error
		font-size: 0.8vw; }

	.btn { // default button
		//padding:
		//	left: 1.08vw
		//	right: 1.08vw
		//	top: 0.83vw
		//	bottom: 0.83vw
		min-width: 123px;
		font-family: $p-r,sans-serif;
		font-size: 15px;
		background-color: #fff;
		cursor: pointer;
		transition: background-color 400ms ease-in-out, border-color 400ms ease-in-out; }

	.label { // input label
		font-size: 15px;
		left: 17px;
		//transition: font-size .3s ease-in
		&_active {
			font-size: 12px!important;
 } }			//transition: font-size .3s ease-in
	//.input // standart input
	//	height: 3.16vw
 }	//	font-size: 1.16vw
@media(min-width: 1920px) {
	.input {
		font-size: 15px; }
	.label { // input label
		font-size: 18px; }
	.general-error {
		font-size: 0.7vw; } }
//@import "~air-datepicker/dist/css/datepicker.min.css";

// если прописать в форме не реат, то везде будет 8пх шрифт
//.row  .form-group
//	.label
//		font-size: 8px!important

.input[name="phone"] {
	letter-spacing: 2px;
	-moz-appearance: textfield; }

