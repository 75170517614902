@import "includes/_reset";
@import "includes/_vars";
@import "includes/hours";
@import "includes/footer";
@import "../../views/blocks/menu/_menu";
@import "includes/form";
@import "../../views/blocks/btn/_btn";
@import "includes/gen";

//растягиваем body на весь экрна пока грузиться форма
body {
	min-height: 100vh;
	display: flex;
	flex-direction: column; }
.section {
	flex-grow: 1; }

.error_container {
	padding-top: 24px;
	padding-bottom: 101px;
	flex-direction: column-reverse;
	justify-content: space-around;
	align-items: center; }

.error_col_left {
	display: flex;
	flex-direction: column;
	width: 100%;
	text-align: center;
	&_title {
		font-size: 71px;
		line-height: 85px;
		color: #E54360;
		font-family: $p-b, sans-serif;
		margin-bottom: 26px; }
	&_text {
		font-size: 23px;
		line-height: 130.5%;
		color: #7A6569;
		font-family: $p-m, sans-serif;
		margin-bottom: 21px; }
	&_link {
		display: flex;
		align-items: center;
		cursor: pointer;
		font-size: 15px;
		line-height: 19px;
		color: #E54360;
		justify-content: center;
		font-family: $p-m, sans-serif;
		svg {
			margin-left: 10px; } } }

.error_col_right {
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-bottom: 30px;
	text-align: center;
	&_title {
		font-size: 67px;
		line-height: 85px;
		color: #7A6569;
		font-family: $p-b, sans-serif; }
	&_text {
		font-size: 193px;
		line-height: 139px;
		color: #7A6569;
		font-family: $p-b, sans-serif; } }

@media(min-width: 996px) {
	.error_container {
		flex-direction: row; }

	.error_col_left {
		width: 30%;
		text-align: left;
		&_link {
			justify-content: flex-start; } }

	.error_col_right {
		//width: 32%
		width: 36%;
		margin-bottom: 0;
		text-align: left;
		&_title {
			font-size: 71px; }
		&_text {
			font-size: 199px; } } }

@media(min-width: 1130px) {
	.error_col_right {
		width: 32%; } }
