@import "./vars";

.hours-wrapper {
	display: flex;
	flex-direction: row;
	align-items: center;
	border: 1px solid transparent;
	padding: 5px 7px 7px 7px;
	height: 27px;
	border-radius: 21px;
	position: absolute;
	top: 0;
	right: -18px;
	margin-right: 15px;
	margin-left: 5px;
	min-width: 117px; }

.hours-title {
	color: #FFFFFF;
	font-size: 13px;
	font-family: $p-m;
	line-height: 120%;
	white-space: nowrap; }
#hours {
	display: block; }

.loading {
	color: #ffffff;
	text-align: center; }
.timer {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background: transparent; }
.timer-hours,.timer-min {
	display: flex;
	overflow: hidden;
	text-align: center; }
.hours,.min {
	display: flex;
	flex-direction: column;
	width: 21px;
	height: 15px;
	color: #4D4D4D;
	font-family: $p-r,sans-serif;
	will-change: transform;
	transition: transform 300ms ease;
	span {
		padding-bottom: 10px;
		font-size: 16px;
		font-weight: bold;
		color: #fff; } }
//.dot
//	margin-left: 5px
//	margin-right: 10px
//	position: relative
.dot {
	margin-left: 0px;
	margin-right: 5px;
	position: relative; }
.dot__item {
	width: 4px;
	height: 4px;
	background-color: #fff;
	border-radius: 100%;
	animation-name: blinker;
	animation-iteration-count: infinite;
	animation-timing-function: easy;
	animation-duration: 1220ms;
	&:nth-child(1) {
		position: absolute;
		top: -3px; }
	&:nth-last-child(1) {
		position: absolute;
		bottom: -8px; } }

@keyframes blinker {
	from {
		opacity: 1; }
	to {
		opacity: 0.2; } }

@media (min-width: 768px) {
	.hours-wrapper {
		border: 1px solid #fff;
		position: relative;
		top: unset;
		right: unset; } }

@media (min-width: 1130px) {
	.hours-wrapper {
		margin-right: 10px; } }

