$popup-z-index: 1000000;

.Button_Close {
    width: 30px;
    height: 30px;
    right: 15px;
    top: 15px;
    position: absolute;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: $popup-z-index + 5; }

.Hider {
    display: none;
    position: fixed;
    overflow: hidden;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    z-index: $popup-z-index - 1;

    //opacity: 0.7
    background-color: rgba(62, 68, 74, 0.7); }

.Popup {
    // POPUP DEFAULTS
    // Common
    display: none;
    position: fixed;
    box-sizing: border-box;

    // Scroll Inertion
    -webkit-overflow-scrolling: touch;

    // Centering
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;

    // Transition
    transition: 0.5s;
    /* ------------ Template Popup ------------ */
    /* Replace "Template" to your own class */
    &.Autf {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        min-width: 88%;
        //max-width: 600px
        box-sizing: border-box;
        border: 1px solid #c8c8c8;
        width: auto;
        height: auto;
        z-index: $popup-z-index;
        visibility: hidden;
        opacity: 0;
        padding: 0;
        background-color: #fff;
        border-radius: 20px;
        transition: height 300ms ease-in-out;


        &.Visible { // Notification show
            visibility: visible;
            opacity: 1; }

        & .Popup_Header {
            text-align: center;
            width: 100%; }

        //height: 100%
        //display: flex
        //flex-direction: column
        //align-items: center
        //justify-content: center
        .input_error {
            bottom: -17px; }
        & .Popup_Title {
            font: {
                size: 4.3vw;
                family: sans-serif;
                weight: 900; }
            color: white;
            //padding: 1% 0 2%
            margin-bottom: 5.8vw; }

        & .Popup_Text {
            font: {
                size: 3.1vw;
                family: sans-serif;
                weight: 500; }
            color: white; }
        .form {
            padding: 40px 5px;
            padding-bottom: 20px;
            @media( min-width: 425px) {
                padding: 40px 10px; }
            @media( min-width: 475px) {
                padding: 40px 40px; } }
        .input {
            border: 1px solid #b3b3b3; }
        .form-group {
            margin: 7px 0px 24px 0px; }
        .form-group-btn {
            margin-top: 0;
            width: 100%;
            padding-top: 0;
            //width: 42%
            //position: absolute
            //bottom: 40px
            .btn {
                display: flex;
                align-items: center;
                height: 50px;
                box-sizing: border-box;
                background-color: #e54360;
                border-radius: 25px;
                border: 1px solid #e54360;
                cursor: pointer;
                font-size: 14px;
                font-family: $p-m, sans-serif;
                transition: color.45s, background-color.45s;
                width: 100%;
                &:hover {
                    color: #e54360;
                    background-color: transparent;
                    transition: color.45s, background-color.45s; } } } }
    .form-group-btn-footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 12px;
        justify-content: space-between;
 }        // padding-top: 30px
    .btn.btn-login {
        justify-content: center; } }

.form-login-title {
    margin-bottom: 24px; }
.form-reg-title {
    margin-bottom: 24px; }
.form-reg-sub-title {
    color: #999999;
    font-family: $p-r, sans-serif;
    font-size: 15px;
    text-align: center;
    margin-bottom: 12px; }
.Header_Popup_Abs {
    display: flex;
    flex-direction: row;
    justify-content: flex-end; }
.link-restor-password {
    cursor: pointer;
    padding-top: 20px;
    color: #E54360;
    font-size: 15px;
    line-height: 19px;
    font-family: $p-r, sans-serif; }
.show-new-modal {
    &.link-restor-password {
        border: none;
        margin: 0 auto;
        width: max-content;
        border-bottom: 1px dashed currentColor;
        transition: border.45s, color.45s;
        &:hover {
            color: #7a6668;
            border-bottom: 1px dashed #7a6668; } }

    &.Reg_Link, &.Log_Link, &.Restore_Link {
        //padding: 12px 55px
        padding: {
            top: 12px;
            bottom: 12px; }
        box-sizing: border-box;
        border-radius: 25px;
        border: 1px solid #E54360;
        color: #E54360;
        cursor: pointer;
        text-transform: uppercase;
        font-family: $p-m, sans-serif;
        font-size: 14px;
        width: 100%;
        height: 50px;
        // margin-left: 10px
        display: flex;
        align-items: center;
        justify-content: center;
        transition: color.45s, background-color.45s;
        &:hover {
            background-color: #E54360;
            color: #fff;
            transition: color.45s, background-color.45s; } }
    &.Log_Link {
        border: none;
        margin: 0 auto;
        width: max-content;
        border-bottom: 1px dashed currentColor;
        transition: border.45s, color.45s;
        border-radius: 0;
        padding-bottom: 4px;
        height: auto;
        &:hover {
            color: #7a6668;
            border-bottom: 1px dashed #7a6668;
            background-color: transparent; } } }

.auth-popup-title {
    color: #7a6668;
    text-align: center;
    font-family: $p-b, sans-serif;
    line-height: 35px;
    font-size: 22px; }
.reg_text {
    font-size: 15px;
    line-height: 19px;
    text-align: center;
    color: #7A6569;
    font-family: $p-r, sans-serif;
    margin-bottom: 18px; }
.social_list {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #F8F8F8;
    margin-bottom: 10px;
    &:before {
        content: '';
        position: absolute;
        left: 0;
        width: 64px;
        height: 64px;
        background-color: #F8F8F8;
        @media( min-width: 992px) {
            height: 80px; } }

    &:after {
        content: '';
        position: absolute;
        right: 0;
        width: 64px;
        height: 64px;
        background-color: #F8F8F8;
        @media( min-width: 992px) {
            height: 80px; } }
    .social_icon {
        width: 40px;
        height: 40px;
        margin-right: 6px;
        margin-top: 8px;
        margin-bottom: 16px;
        &:last-child {
            margin-right: 0; }
        svg {
            width: 100%;
            height: 100%; } } }
.btn-restore {
    width: 100%;
    background: #e54360;
    border: 1px solid #e54360;
    border-radius: 25px;
    font-size: 17px;
    line-height: 22px;
    text-transform: uppercase;
    color: #fff;
    cursor: pointer;
    padding: 12px;
    text-align: center;
    min-width: 100px;
    font-family: $p-r,sans-serif;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    justify-content: center;
    //max-width: max-content
    &:hover {
        background-color: transparent;
        color: #e54360;
        transition: color.45s, background-color.45s ease-in-out; } }

@media screen and (min-width: 768px) {
    .Popup {
        &.Autf {
            min-width: 420px;
            max-width: 420px; } }
    .auth-popup-title {
        font-size: 25px; } }

@media screen and (min-width: 992px) {

    .Button_Close {
        height: 1vw;
        width: 1vw;
        transform: scale(1);
        transition: transform 300ms ease-in-out;
        cursor: pointer;
        &:hover {
            transform: scale(1.2);
            transition: transform 300ms ease-in-out; } }

    .Popup {
        &.Template {
            & .Popup_Title {
                font-size: 1.3vw;
                margin-bottom: 0.8vw; }
            & .Popup_Text {
                font-size: 1.1vw; } }
        // .form-group-btn-footer
 }        //  // padding-top: 30px
    .social_list {
        .social_icon {
            width: 56px;
            height: 56px; } } }

/* form call back*/


/* ------------ Template Popup END ------------ */

@media screen and (min-width: 992px) {

    .Button_Close {
        height: 1vw;
        width: 1vw;
        width: 30px;
        height: 30px;
        transform: scale(1);
        transition: transform 300ms ease-in-out;
        cursor: pointer;
        &:hover {
            transform: scale(1.2);
            transition: transform 300ms ease-in-out; } } }

//@media( min-width: 310px) and (max-width: 540px)
//    .Popup
//        &.Autf
//            height: 88%
.Popup {
    &.Autf {
 } }        // min-height: 320px;
@media( min-width: 768px) {
    .Popup {
        &.Autf {
            min-height: 324px; } } }

.form-choice-wrapper {
    padding: 20px 0;
    position: relative;
    // &:before
    //     content: ''
    //     position: absolute
    //     top: 0
    //     left: 0
    //     width: 143%
    //     height: 100%
    //     transform: translateX(-15%)
 }    //     background-color: rgba(229, 67, 96, 0.33)

.form-choice {
    display: flex;
    flex-direction: column;
    gap: 7px;
    align-items: center; }
//     justify-content: center

.form-choice-item {
    position: relative;
    font-family: $p-b;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 17px;
    text-transform: uppercase;
    color: #FFFFFF;
    // padding-left: 20px
    cursor: pointer;

    height: 50px;
    width: 360px;
    display: flex;
    border-radius: 50px;
    align-items: center;
    justify-content: center;
    background: #E54360;
    border: 1px solid #e54360;
    transition: color.45s, background.45s;
    @media(min-width: 360px) {
        font-size: 13px; }

    @media(min-width: 768px) {
        font-size: 14px;
 }        // padding-left: 25px

    // &:first-child
    //     margin-right: 5px
    //     @media(min-width: 360px)
    //         margin-right: 20px
    //     @media(min-width: 768px)
    //         margin-right: 25px

    // &:before
    //     content: ''
    //     position: absolute
    //     top: 50%
    //     left: 0
    //     transform: translate(0, -50%)
    //     width: 15px
    //     height: 15px
    //     background-color: #FFFFFF
    //     border-radius: 15px

    // &:after
    //     content: ''
    //     position: absolute
    //     top: 50%
    //     left: 0
    //     transform: translate(35%, -50%)
    //     width: 9px
    //     height: 9px
    //     background-color: rgba(229, 67, 96, 0.1)
    //     border-radius: 15px

    &.active {
        background-color: #65677A;
        border: 1px solid #65677A;
        color: #fff; }

    &.active.form-choice-item.Reg_Link {
        width: 360px;
        margin-left: 0px;
        padding-top: 15px;


        // &:before
        //     background-color: #F3A5B3

        // &:after
 }        //     background-color: #E54360
    &:hover {
        color: #e54360;
        background-color: transparent; } }

.woman-btn-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 50px; }

.woman-btn {
    position: relative;
    cursor: pointer;
    background: rgba(229, 67, 96, 0.90);
    border-radius: 25px;
    padding: 10px 28px;
    font-family: $p-b;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: #FFFFFF;
    svg {
        position: absolute;
        top: -10px;
        right: -15px;
        animation-name: heartBeat;
        animation-duration: 1.3s;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
        animation-delay: 4s; } }

