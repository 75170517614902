$p-b: Pusia-Bold;
$p-m: Pusia-Medium;
$p-r: Pusia-Regular;
$rob: Roboto;
$svg_font: Aerokids;

$full_path: '/fonts/';
$path_site: '/';
$title_color: #333;

@mixin font_include($name,$folder,$name_url) {

	@font-face {
		font-family: quote($name);

		font-display: swap;
		src: url(#{$full_path}#{$folder}/#{$name_url}.woff2) format('woff2'), url(#{$full_path}#{$folder}/#{$name_url}.woff) format('woff'), url(#{$full_path}#{$folder}/#{$name_url}.ttf ) format('truetype'); } }

