//+font_include(Pusia-Medium,Pusia-Medium,Pusia-Medium)
.btn {
	//width: 164px
	//height: 37px
	border-radius: 25px;
	font: {
		size: 17px;
		family: $p-r, sans-serif; }

	&.btn-red {
		display: flex;
		align-items: center;
		justify-content: center;
		background: #E44360;
		border: 1px #E44360 solid;
		cursor: pointer;
		color: white;
		min-height: 42px;
		transition: background.45s, color.45s;
		&:hover {
			background: transparent;
			color: #E44360;
			border: 1px #E44360 solid;
			transition: background.45s, color.45s; } }

	&.btn-transparent {
		background: rgba(229, 67, 96, 0.46);
		border: 1px #E44360 solid;
		cursor: pointer;
		color: #fff;
		transition: background.45s, color.45s;

		min-height: 42px;
		&:hover {
			background: #E44360;
			color: white;
			border: 1px #E44360 solid;
			transition: background.45s, color.45s; } } }

@media (min-width: 768px) {
	.btn {
		&.btn-red {
			min-width: 186px; }
		&.btn-transparent {
			min-width: 227px; } } }
