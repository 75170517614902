
// Universal spinner (inserted via JS)
.spinner {
    &_background {
        position: absolute;
        width: 100%;
        height: 100%;
        transform: translate(-50%, -50%);
        left: 50%;
        top: 50%;
        z-index: 100000;

        &--cover {
            background-color: rgba(255,255,255,0.5); } }

    &__svg {
        animation: spinner-rotate 2s linear infinite;
        z-index: 2;
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -25px 0 0 -25px;
        width: 50px;
        height: 50px;
        &__path {
            stroke: hsl(210, 70, 75);
            stroke-linecap: round;
            animation: spinner-dash 1.5s ease-in-out infinite; } } }

.spinner__active {
    width: auto;
    height: 100%;
    .spinner__svg {
        left: 0;
        top: 0;
        margin: 0px 0 0 -10px;
        width: auto;
        height: 100%; } }

.container__disabled {
    overflow: hidden;
    pointer-events: none;
    position: relative;
    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        cursor: default;
        background-color: #dcdcdc66;
        z-index: 100; } }


@keyframes spinner-rotate {
    100% {
        transform: rotate(360deg); } }

@keyframes spinner-dash {
    0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0; }
    50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35; }
    100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124; } }

// Spinner END
