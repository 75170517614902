$popup-z-index: 1000000;
$popup-z-index-hidden: -1;

.Button_Close_Thank {
	width: 30px;
	height: 30px;
	right: 15px;
	top: 15px;
	position: absolute;
	background-repeat: no-repeat;
	background-size: contain;

	z-index: $popup-z-index + 5;
	svg {
		fill: #ec788d; } }

.Popup {

	&.ThankYou {
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		z-index: $popup-z-index;
		visibility: hidden;
		opacity: 0;
		background-color: #E54360;
		color: white;
		//transform: translate(-50%,-50%);
		left: 50%;
		min-width: 300px;
		max-width: 350px;
		height: 350px;
		width: 100%;
		//width: 88%;
		top: 50%;
		border-radius: 50%;

		.form-default-popup-1-thank {
			.success {
				text-align: center;
				display: none;
				svg {
					.fil0 {
						fill: #fff; }
					.fil1 {
						fill: #fff;
						fill-rule: nonzero;
						fill-opacity: 0.721569; }
					.fil2 {
						fill: #fff; } } }
			.error {
				text-align: center;
				display: none;
				svg {
					.fil0 {
						fill: #fff; }
					.fil1 {
						fill: #fff;
						fill-rule: nonzero;
						fill-opacity: 0.721569; }
					.fil2 {
						fill: #fff; } } }

			.popup-subTitle-thank {
				font-family: $p-r,sans-serif;
				font-style: normal;
				font-weight: normal;
				font-size: 16px;
				line-height: 35px;
				text-align: center;
				color: #fff;
				padding-top: 0;
				padding-bottom: 0; } }

		&.Visible { // Notification show
            visibility: visible;
            opacity: 1; }

		&.Success {
			.success {
				display: block; } }

		&.Fail {
			.error {
				display: block; } } } }

@media (min-width: 785px) {
	.Popup {
		&.ThankYou {
			width: 100%;
			max-width: 500px;
			height: 500px;
			//height: auto

			.form-default-popup-1-thank {
				width: 100%;
				padding: 70px;

				.popup-subTitle-thank {
					font-family: $p-r,sans-serif;
					font-style: normal;
					font-weight: normal;
					font-size: 22px;
					line-height: 35px;
					text-align: center;
					color: #fff;
					padding-top: 25px;
					padding-bottom: 0; } } } } }

@media screen and (min-width: 992px) {
	.Button_Close_Thank {
		//height: 1vw
		//width: 1vw
		width: 30px;
		height: 30px;
		transform: scale(1);
		transition: transform 300ms ease-in-out;
		cursor: pointer;
		&:hover {
			transform: scale(1.2);
			transition: transform 300ms ease-in-out; } } }
