//.search
//	padding: 5px 0;
//	width: 135px;
//	height: 30px;
//	position: relative;
//	left: 10px;
//	float: left;
//	line-height: 22px;
//.search .newT
//	padding: 0;
//	border-radius: 25px;
//	position: absolute;
//	width: 0px;
//	float: Left;
//	margin-left: 125px;
//	transition: all 0.3s ease-in-out;
//	background: #e44360;
//	height: 30px;
//	font-size: 13px;
//	color: #000;
//	outline: none;
//	border-width: 0;
//
//	overflow: hidden;
//	white-space: nowrap;
//	word-wrap: normal;
//	text-overflow: ellipsis;
//.search:hover .newT ,  .search .newT:focus
//	width: 130px;
//	margin-left: 0px;
//	transition: all 0.3s ease-in-out;
//.search .newT:focus
//	background: #000;
//	color: #999;
//	transition: all 0.3s ease-in-out;
.srch_btn {
	height: 30px;
	width: 30px;
	border-radius: 50px;
	background: #e44360 no-repeat center center;
	position: absolute;
	right: 0; }

.main_menu_wrapper {
	@media(min-width: 768px) {
		margin-left: 10px; }
	//@media(min-width: 768px) and (max-width: 995px)
 }	//	margin-right: 40px

.separator {
	display: none;
	background: #fff;
	width: 1px;
	height: 32px; }

.background_menu {
	background-color: #7A6569;
	width: 100%;
	height: 100px; }

.maine_menu_btn_group {
	display: none; }
@media(min-width: 768px) {
	.maine_menu_btn_group {
		display: flex;
		flex-direction: row;
		align-items: center;
		@media(min-width: 768px) and (max-width: 995px) {
			position: absolute;
			bottom: 10px;
 }			//transform: translateY(-50%)
		.btn.btn-red {
			min-width: auto;
			min-height: auto;
			margin-right: 14px;
			white-space: nowrap;
			&:nth-child(1) {
				padding: 9px 21px 9px 20px; }
			&:last-child {
				padding: 9px 16px 9px 16px; } } } }
.main_menu_search {
	display: none; }
@media(min-width: 768px) {
	.main_menu_search {
		display: block;
		position: relative;
		&--hidden {
			visibility: hidden; }
		&_icons {
			position: relative;
			z-index: 2;
			&:hover {
				background-color: #e44360!important; } }
		&_text {
			white-space: nowrap;
			color: #fff;
			font-size: 12px;
			line-height: 13px;
			font-family: $p-r,sans-serif;
			position: absolute;
			//top: 50%
			//left: 50%
			//will-change: transform
			//transform: translate(-100%, -50%)
 }			//transition: transform.55s ease-in-out
		&_text_wrap {
			height: 100%;
			display: flex;
			align-items: center;
			z-index: 2;
			transition: all.45s ease-in-out;
			will-change: transform;
			position: absolute;
			left: 48px;
			top: 0;

			//right: 10px

   //       //overflow: hidden
   //       //left: 50%
			//transform: translate(0,-48%)
			//transform: translate(-80%,-48%)
			//-webkit-transform: translate(-65%,-48%)
			//background-color: #e44360
			//border-radius: 50px
			//width: 0
			//padding-left: 10px
 }			//padding-right: 30px

		//&:hover
		//	//overflow: visible
		//	.main_menu_search_text
		//		transform: translate(-60%,-50%)
		//		will-change: transform
		//		transition: transform.55s ease-in-out
		//	.main_menu_search_text_wrap
		//		width: 155px
		//		//transform: translate(-94%, -48%)
		//		//transform: translate(-80%, -48%)
		//		transform: translate(0, -48%)
		//		will-change: transform
		//		transition: all.45s ease-in-out

		.btn.btn-red {
			justify-content: flex-start;
			margin-left: 10px;
			padding: 8px;
			min-width: 35px;
			min-height: 35px;
			width: 150px;
			height: 35px;
			//svg
			//	width: 100%
 } } }			//	height: 100%

.mobile_menu_btn {
	display: block;
	cursor: pointer;
	width: 25px;
	transform: rotate(0deg);
	transition: transform.45s;
	&.active {
		transform: rotate(45deg);
		transition: transform.45s;
		z-index: 999999999;
		svg {
			path, ellipse {
				fill: #E54360; } } } }
.mobile_menu_modif {
	position: absolute;
	right: 10px;
	top: 8px; }
.btn-logout_desc {
	display: none; }
.btn-logout_mob {
	display: flex;
	margin-top: 20px; }
.main_menu {
	background-color: #7A6569;
	font-family: $p-m, sans-serif;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 1002;
	height: 100px;
	overflow: hidden;
	transition: height 300ms ease-in-out;
	.main_menu_groupBtn {
		//width: 148px
		width: max-content; }
	.container {
		justify-content: space-between;
		height: 100px;
		flex-wrap: nowrap; }

	.row_first_list {
		display: flex; }

	&_logo {
		svg {
			width: 105px;
			height: 86px; } }

	&_col_left {
		//margin-right: 69px
		//margin-right: 7px
		display: flex;
		align-items: center; }

	&_row_first {
		order: 1;
		display: flex;
		align-items: flex-end;
		justify-content: flex-end;
		flex-direction: column;
		width: 32%; }
	&_row_second {
		display: flex;
		align-items: center;
		//justify-content: space-between
		justify-content: flex-end;
		width: 100%; }
	&_phone {
		margin-right: 17px;
		color: white;
		font-size: 13px;
		&:last-child {
			margin-right: 24px; } }
	&_phone_group {
		display: none; }
	&_time {
		p {
			display: none; } }
	&_social_list {
		display: none; }
	&_languages_list {
		display: none; }
	&_col_right {
		display: flex;
		justify-content: space-around;
		position: relative;
		//width: 100%
		padding-bottom: 8px;
		flex-direction: row;
		align-items: center; }

	&_list {
		display: none; }

	&_groupBtn {
		position: relative;
		background: #fff;
		cursor: pointer;
		border-radius: 40px;
		display: flex;
		//padding: 7px 22px 11px 29px
		width: 190px;
		height: 35px;
		justify-content: center;
		align-items: center;
		.btn_love_menu {
			position: absolute;
			left: 18px;
			&:nth-child(5) {
				top: -10px;
				right: 59px; }
			&:last-child {
				top: 0; } }
		.btn-sp {
			width: 1px;
			height: 24px;
			background: #E44360;
			margin-left: 14px;
			margin-right: 13px; }
		.menu_btn {
			color: #E44360;
			font-size: 15px;
			font-family: $p-m, sans-serif;
			position: relative;
			svg {
				position: absolute; } } } }
.mobile_menu_overlay {
	//height: calc(100vh - 100px)
	height: calc(100vh + 20px);
	width: 100%;
	background: rgba(122, 101, 105, .7);
	opacity: 0;
	visibility: hidden;
	position: fixed;
	//z-index: 9999999999998
	//position: relative
	//transition: all.45s
	&.active {
		opacity: 1;
		visibility: visible;
		transition: all.45s; } }
.mobile_hider {
	background-color: rgba(62, 68, 74, 0.7);
	height: calc(100% - 100px);
	z-index: -1;
	position: absolute;
	width: 100%;
	left: 50%;
	top: 100%;
	transform: translate(-50%, -100%);
	transition: all.45s;
	opacity: 0;
	visibility: hidden;
	&.active {
		opacity: 1;
		visibility: visible;
		transition: all.45s; } }
.mobile_Nav {
	background: #7A6569;
	height: calc(100vh - 100px);
	//transform: translate(100%, -39%)
	//opacity: 0
	//left: 0
	//bottom: 0
	//padding-top: 100px
	//position: fixeds
	//visibility: hidden
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	width: 100%;
	transition: all.45s;
	z-index: 9999;
	overflow-Y: auto;
	-webkit-overflow-scrolling: touch;
	&.active {
		visibility: visible;

		//transform: translate(-50%, -39%)
		opacity: 1;
		transition: all.45s; }
	&_ios {
		height: calc(100vh - 200px)!important; }
	&_footer {
		width: 100%;
		border-top: 1px solid #fff;
		margin-left: 12px;
		margin-right: 12px; }
	.mobile_col_left {
		width: 50%;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start; }
	.mobile_col_right {
		width: 50%;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-end;
		padding-right: 16px; }
	.main_menu_phone_group {
		display: flex;
		//flex-direction: column
		justify-content: flex-start;
		align-items: center;
		padding-top: 5px;
		.main_menu_phone {
			font-size: 15px;
			font-family: $p-r,sans-serif;
			color: rgba(255, 255, 255, 0.6); } }
	.main_mobile_menu_list {
		display: flex;
		flex-direction: column;
		padding-left: 16px;
		li {
			//margin-bottom: 10px
			margin: auto;
			padding-bottom: 12px;
			padding-top: 12px;
			//border-bottom: 1px dotted #fff
			width: 100%;
			&.sp {
				padding: 0;
				margin-top: 10px;
				margin-bottom: 10px;
				background-color: #fff;
				height: 1px; }
			.menu_link {
				color: #fff;
				white-space: nowrap;
				line-height: 22px;
				font: {}
				size: 17px;
				family: $p-r, sans-serif;
				&.active {
					border-bottom: 2px solid #fff; }
				&.red {
					//color: #e44360
					font-family: $p-b, sans-serif;
					&:hover {
						color: #ffffff; } } } }

		.main_menu_list {
			display: flex;
			flex-direction: column; } } }

.main_mobile_menu_languages_list {
	display: flex;
	flex-direction: column;
	align-items: center;
	border-bottom: 1px solid #FFFFFF;
	padding-bottom: 19px;
	padding-top: 12px;
	margin-bottom: 9px;
	.language_item {
		color: white;
		cursor: pointer;
		position: relative;
		font-size: 13px;
		display: flex;
		justify-content: center;
		align-items: center;
		border: 1px solid;
		border-color: transparent;
		width: 29px;
		height: 29px;
		border-radius: 50px;
		margin-bottom: 15px;
		transition: border-color.45s;
		//&:before
		//	content: ''
		//	position: absolute
		//	border: 1px solid #fff
		//	width: 25px
		//	height: 25px
		//	border-radius: 50px
		//	opacity: 0
		//	transition: opacity.45s
		&:last-child {
			margin-bottom: 0; }
		&:hover {
			border-color: #fff;
			transition: border-color.45s; }

		&.language_item_active {
			border-color: #fff; } } }
.main_menu_time_modif {
	margin-top: 5px;
	padding-bottom: 60px;
	display: block;
	font-size: 15px;
	font-family: $p-r,sans-serif;
	color: rgba(255, 255, 255, 0.6);
	text-align: left;
	p {
		display: block; } }
.main_menu_social_list_modif {
	display: flex;
	justify-content: space-around;
	width: auto;
	align-items: center;
	flex-direction: column;
	margin-left: 0;
	margin-right: 4px;
	padding-top: 10px;
	.list_item {
		margin-bottom: 23px;
		margin-top: 6px;
		img {
			width: 17px;
			height: 17px; }
		&:last-child {
			margin-bottom: 0; } } }

// menu btn
.btn-ystory { // TODO: Not used?
	display: none; }
.btn-profile {
	position: relative;
	//padding-right: 17px
	//padding-left: 17px
	padding-right: 10px;
	padding-left: 10px;
	font-size: 14px;
	width: auto;
	white-space: nowrap;
	svg {
		position: absolute;
		top: -21px;
		right: -13px;
		animation-name: heartBeat;
		animation-duration: 1.3s;
		animation-timing-function: ease-in-out;
		animation-iteration-count: infinite;
		animation-delay: 4s; } }
.btn-reg {
	position: relative;
	width: 148px;
	display: flex;
	justify-content: center;
	svg {
		position: absolute;
		top: -26px;
		right: 8px;
		animation-name: heartBeat;
		animation-duration: 1.3s;
		animation-timing-function: ease-in-out;
		animation-iteration-count: infinite;
		animation-delay: 4s; } }
@keyframes heartBeat {
	0% {
		transform: scale(1); }
	14% {
		transform: scale(1.1); }
	28% {
		transform: scale(1); }
	42% {
		transform: scale(1.1); }
	70% {
		transform: scale(1); } }

.btn-sp {
	display: none; }
.main_menu_groupBtn {
	//width: 148px
	width: max-content; }
.mobile_menu_btn {
	margin-right: 10px;
	margin-left: 10px; }

.main_menu_receiving_clients {
	display: none; }
.btn-second-row {
	display: none; }
@media (min-width: 768px) {
	.btn-logout_desc {
		display: flex;
		margin-left: 19px; }
	.btn-logout_mob {
		display: none; }
	.mobile_menu_btn {
		margin-right: 0; }
	.main_menu {
		&_col_right {
			flex-direction: column;
			padding-top: 5px; }
		&_row_first {
			flex-direction: row;
			align-items: center;
			order: 0;
			width: 100%; } }
	.mobile_Nav {
		height: calc(100vh - 100px);
		bottom: 0;
		top: auto; }
	.mobile_menu_modif {
		display: none; }
	.mobile_menu_btn {
		display: none; }
	.btn-second-row {
		display: block;
		margin-right: 15px; }
	.separator {
		display: block;
		&--hidden {
			visibility: hidden; } }
	.main_menu {
		//height: 105px!important
		//padding-top: 5px
		&_row_first {
			justify-content: space-between; }
		&_time {
			font-size: 13px;
			p {
				display: block; } }
		&_phone_group {
			display: flex; }
		&_col_left {
			margin-right: 20px; }
		&_phone {
			//font-size: 7px
			margin-right: 5px;
			&:last-child {
				margin-right: 5px; } }
		&_time {
			color: white;
			display: flex;
			align-items: center; }
		&_receiving_clients {
			display: block;
			font-family: $p-r,sans-serif;
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 17px;
			color: #FFFFFF; }
		&_languages_list {
			display: none;
			//display: flex
			align-items: center;
			margin-left: 14px;
			.language_item {
				color: white;
				cursor: pointer;
				position: relative;
				font-size: 13px;
				display: flex;
				justify-content: center;
				align-items: center;
				margin-right: 9px;
				border: 1px solid;
				border-color: transparent;
				width: 25px;
				height: 25px;
				border-radius: 50px;
				transition: border-color.45s;
				//&:before
				//	content: ''
				//	position: absolute
				//	border: 1px solid #fff
				//	width: 25px
				//	height: 25px
				//	border-radius: 50px
				//	opacity: 0
				//	transition: opacity.45s
				&:hover {
					border-color: #fff;
					transition: border-color.45s; }

				&.language_item_active {
					border-color: #fff; } }
			.language_item {
				margin-right: 17px; } }
		&_social_list {
			display: flex;
			align-items: center;
			margin-left: 13px;
			//margin-right: 47px
			.list_item {
				&:first-child {
					margin-right: 26px; }
				&:nth-child(2) {
					margin-right: 23px; }
				&:nth-child(3) {
					margin-right: 20px; } } } }
	.main_mobile_menu_languages_list {
		display: flex; }
	.mobile_Nav {
		width: 50%; }
	//	&.active
	//		width: 50%
	.main_menu_time_modif {
		display: none; }
	.main_menu_social_list_modif {
		display: none; }
	.main_menu_phone_group_modif {
		display: none!important; } }

@media (min-width: 996px) {
	.main_menu {
		&_col_left {
			margin-right: 0; }
		&_phone {
			font-size: 13px;
			margin-right: 17px;
			&:last-child {
				margin-right: 24px; } }
		&_time {
			font-size: 13px;
			//margin-left: 15px
 }			//margin-right: 15px

		&_social_list {
			margin-left: 23px;
			margin-right: 20px; } } }

@media (min-width: 1130px) {
	.mobile_menu_btn {
		display: none; }

	.main_menu {
		&_languages_list {
			display: flex;
			.language_item {
				margin-right: 7px; } }
		&_time {
			width: auto;
			//margin-left: 10px
			margin-right: 10px; }
		&_list {
			display: flex;
			justify-content: space-around;
			width: 77%;
			align-items: center;
			.menu_link {
				color: #ffffff;
				font-family: $p-m, sans-serif;
				font-size: 13px; }
			&.ru, &.uk {
				li {
					position: relative;
					&:after {
						content: "";
						position: absolute;
						width: 5px;
						height: 5px;
						border-radius: 5px;
						top: 50%;
						right: -9px;
						background-color: white; }
					&:last-child {
						&:after {
							content: none; } } } } }
		&_row_second {
			justify-content: space-between; }

		//&_groupBtn
		//	width: 230px
 } }		//	height: 40px
@media (min-width: 1200px) {
	.btn-logout_desc {
		position: absolute;
		right: -42px; } }

.home_men_registration {
	border: 2px solid #E54360;
	box-shadow: 0px 16px 25px -12px #E54360;
	display: flex;
	align-items: center;
	gap: 15px;
	position: absolute;
	cursor: pointer;
	background-color: #fff;
	border-radius: 35px;
	width: auto;
	max-width: -webkit-max-content;
	max-width: -moz-max-content;
	max-width: max-content;
	right: 50%;
	transform: translateX(50%);
	bottom: -85px;
	min-width: 350px;
	padding: 10px 20px 10px 10px;
	font-family: Pusia-Regular;
	font-style: normal;
	font-weight: 400;
	font-size: 15px;
	line-height: 18px;
	text-align: center;
	color: #e54360;
	z-index: 1;
	transition: color .2s ease-in-out,background-color .2s ease-in-out;
	& .home_men_registration__text {
		font-size: 17px;
		line-height: 20.4px; }
	& svg {
		position: absolute;
		top: -7px;
		right: 5px;
		-webkit-animation-name: heartBeat;
		animation-name: heartBeat;
		-webkit-animation-duration: 1.3s;
		animation-duration: 1.3s;
		-webkit-animation-timing-function: ease-in-out;
		animation-timing-function: ease-in-out;
		-webkit-animation-iteration-count: infinite;
		animation-iteration-count: infinite;

		&:hover {
			background-color: #e54360;
			color: #fff; } } }
@media (min-width: 425px) {
	.home_men_registration {
		right: 65%ç; } }
@media (min-width: 992px) {
	.home_men_registration {
		right: 0; } }
@media (min-width: 768px) {
	.home_men_registration {
		bottom: -69px;
		min-width: -webkit-max-content;
		min-width: -moz-max-content;
		min-width: max-content;
		width: 100%;
		transform: translateX(0); } }

.home_men_registration__arrow {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background-color: #E54360;
	display: flex;
	justify-content: center;
	align-items: center; }

.home_men_registration__arrow-img {
	width: 16.55px; }
