@import "./auth_popup/auth_popup";
$popup-z-index: 1000000;
$popup-z-index-hidden: -1;

.Button_Close {
    width: 30px;
    height: 30px;
    right: 15px;
    top: 15px;
    position: absolute;
    background-repeat: no-repeat;
    background-size: contain;

    z-index: $popup-z-index + 5;
    svg {
        fill: #ec788d; } }

.Hider {
    display: none;
    position: fixed;
    width: 100%;
    height: 101%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(.7);
    z-index: $popup-z-index-hidden - 1;

    background-color: rgba(62, 68, 74, 0.7);
    transition: transform 0.45s;
    &.active {
        transform: translate(-50%, -50%) scale(1);
        z-index: $popup-z-index - 10;
        transition: transform 0.45s; }

    .Confirmation {
        z-index: $popup-z-index+14; } }

.Popup {
    .Popup_Header {
 }        //overflow-y: auto

    .general-error {
        bottom: -18px; }
    // POPUP DEFAULTS
    // Common
    position: fixed;
    box-sizing: border-box;
    z-index: $popup-z-index-hidden;

    max-height: 100%;
    overflow: hidden;

    // Scroll Inertion
    -webkit-overflow-scrolling: touch;

    // Centering
    //-ms-transform: translate(-50%, -50%)
    //-webkit-transform: translate(-50%, -50%)
    //-o-transform: translate(-50%, -50%)
    //-moz-transform: translate(-50%, -50%)
    transform: translate(-50%, -50%) scale(.7);
    top: 50%;
    left: 50%;
    opacity: 0;
    //visibility: hidden

    // Transition
    transition: all 0.45s;
    &.Visible {
        z-index: $popup-z-index + 10;
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
        transition: all 0.45s; }
    &.Active {
        display: block; }
    // POPUP DEFAULTS END

    &.Autf {
        overflow-y: auto; }
    .Popup_Svg {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-bottom: 22px; }
    .Popup_Svg_Good, .Popup_Svg_Bad, .Popup_Svg_Info {
        display: none; }

    &.Success {
        .Popup_Svg_Good {
            display: block;
            width: 30px;
            height: 30px;
            svg {
                width: 100%;
                height: 100%;
                fill: #7A6569; } }
        .Close_Popup {
            svg {
                fill: #7A6569!important;
                polygon {
                    fill: #7A6569!important; } } } }
    &.Fail {
        .Popup_Svg_Bad {
            display: block;
            width: 30px;
            height: 30px;
            svg {
                width: 100%;
                height: 100%;
                fill: #7A6569; } } }
    &.Info {
        .Popup_Svg_Info {
            display: block;
            width: 30px;
            height: 30px;
            svg {
                width: 100%;
                height: 100%;
                fill: #7A6569; } }
        .Close_Popup {
            svg {
                fill: #7A6569!important;
                polygon {
                    fill: #7A6569!important; } } } }


    &.Notification {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        min-width: 320px;
        max-width: 600px;
        width: auto;
        height: auto;
        z-index: $popup-z-index+10;
        visibility: hidden;
        opacity: 0;
        padding: 40px 40px 40px 40px;
        background-color: #fff;
        border-radius: 20px;
        &.Visible { // Notification show
            visibility: visible;
            opacity: 1; }
        & .Popup_Header {
            text-align: center;
            overflow-y: hidden; }
        & .Popup_Title {
            font: {
                size: 40px;
                family: $p-b,sans-serif;
                weight: 900; }
            color: #7A6569;
            margin-bottom: 5.8vw; }
        & .Popup_Text {
            font: {
                size: 18px;
                family: $p-m,sans-serif;
                weight: 500; }
            color: #7A6569; } }

    // Notification type Fail

    &.Fail {
        background-color: #E54360; }
    &.Fail .Popup_Title {
        color: #fff; }
    &.Fail .Popup_Text {
        color: #fff; }
    &.Fail .Popup_Svg_Bad {
        svg {
            fill: #fff; } }

    // Popup with Confirmation
    // .Popup.Confirmation // Popup Confirmation
    &.Confirmation {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        min-width: 320px;
        max-width: 600px;
        width: auto;
        height: auto;
        z-index: $popup-z-index+15;
        visibility: hidden;
        opacity: 0;
        padding: 40px 40px 40px 40px;
        background-color: #fff;
        border-radius: 20px;
        &.Visible { // Notification show
            visibility: visible;
            opacity: 1; }
        & .Popup_Header {
            text-align: center; }
        & .Popup_Title {
            font: {
                size: 40px;
                family: $p-b,sans-serif;
                weight: 900; }
            color: #7A6569;
            margin-bottom: 20px; }
        & .Popup_Text {
            font: {
                size: 18px;
                family: $p-m,sans-serif;
                weight: 500; }
            color: #7A6569;
            margin-bottom: 20px; }
        & .Popup_Buttons {
            position: relative;
            //left: -5px
            //right: -5px
            display: flex;
            width: 100%;
            justify-content: center;
            flex-wrap: wrap;
            & .btn {
                //margin-left: 5px
                //margin-right: 5px
                margin: 5px; } } }

    /* Popup_footer Popup */
    &.Popup_footer {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        z-index: $popup-z-index;
        visibility: hidden;
        opacity: 0;
        background-color: rgba(0, 153, 51, .5);
        position: fixed;
        bottom: 0;
        right: 0;
        left: 0;
        color: white;
        top: 70%;
        transform: translate(0%,0%);


        &.Visible { // Notification show
            visibility: visible;
            opacity: 1; }

        & .Popup_Header {
            text-align: center; }

        & .Popup_Title {
            font: {
                size: 4.3vw;
                family: "Roboto",sans-serif;
                weight: 900; }
            color: white;
            //padding: 1% 0 2%
            margin-bottom: 5.8vw; }

        & .Popup_Text {
            font: {
                size: 14px;
                font-family: "Roboto",sans-serif;
                weight: 500; }
            color: white;
            text-align: center;
            padding-right: 5px;
            padding-left: 5px;
            button {
                background: transparent;
                border: none;
                cursor: pointer;
                text-decoration: underline white;
                transition: text-decoration.45s;
                color: white;
                font-size: 13px;
                &:hover {
                    text-decoration: none;
                    transition: text-decoration.45s; } } }

        // Popup_footer type Fail
        &.Fail {
            filter: grayscale(1); }
        &.Fail .Popup_Title {
            color: #B3B3B3; }
        &.Fail .Popup_Text {
            color: #B3B3B3; }

        .footer_btn_group {
            display: flex;
            justify-content: space-around;
            width: 100%;
            .footer_btn {
                padding: 5px 15px 5px 15px;
                border-radius: 40px;
                background: #009933;
                cursor: pointer;
                font: {
                    size: 14px;
                    family: "Roboto",sans-serif; }
                color: white;
                transition: color.45s, background.45s, box-shadow.45s;
                cursor: pointer;
                &:hover {
                    color: #961480;
                    background: white;
                    box-shadow: 0 8px 21px -6px black;
                    transition: color.45s, background.45s, box-shadow.45s; } } } }

    &.Pay {
        h1 {
            font-size: 26px;
            color: #e44360; }
        & .Popup_Header {
            overflow-y: auto; } }

    /* Popup_footer Popup */
    &.Support,&.RequestCall,&.ScheduleComplimentaryCall,&.BookMarchmakersService,&.Questionnaire,&.Pay,&.AnyQuestions,&.Working_with_matchmaker {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: $popup-z-index;
        visibility: hidden;
        opacity: 0;
        background-color: white;
        color: white;
        //transform: translate(-50%,-50%)
        left: 50%;
        min-width: 300px;
        max-width: 356px;
        width: 88%;
        top: 50%;
        border-radius: 20px;
        border: 1px solid #7A6569;


        &.Visible { // Notification show
            visibility: visible;
            opacity: 1; }

        & .Popup_Header {
            text-align: center;
            font-family: "Roboto",sans-serif;
            font-size: 13px;
            color: #B0B1B1;
            width: 100%; }

        & .Popup_Title {
            font-size: 31px;
            font-weight: bold;
            color: black;
            //padding: 1% 0 2%
            margin-bottom: 6px; }

        & .Popup_Text {
            font: {
                size: 14px;
                family: "Roboto",sans-serif;
                weight: 500; }
            color: black;
            height: 70vh;
            overflow: auto;
            position: relative;
            top: -4px;
            padding-right: 10px;
            padding-left: 10px; }
        .form-default-popup-1 {
            width: 100%;
            padding: 38px;
            .popup-title {
                font-family: $p-b,sans-serif;
                font-style: normal;
                font-weight: normal;
                font-size: 21px;
                line-height: 31px;
                text-align: center;
                color: #7A6569;
                padding-bottom: 11px; }
            .popup-subTitle {
                font-family: $p-r,sans-serif;
                font-style: normal;
                font-weight: normal;
                font-size: 11px;
                line-height: 18px;
                text-align: center;
                color: #7A6569;
                padding-bottom: 18px; }
            .input {
                border: 1px solid #B3B3B3;
                height: 45px;
                font-family: Pusia-Regular,sans-serif;
                color: grey; }

            .general-error, .input_error {
                font-size: 11px; }
            .input_error {
                bottom: -16px;
                font-size: 13px; }
            .label.textarea_label {
                top: 19%; }
            .form-group-btn-footer {
                padding: 0; }
            .error_active .input {
                border-color: red; }
            .textarea_input {
                height: 120px;
                resize: none;
                padding: 15px; }
            .form-group-btn {
                margin: 0px;
                width: 100%;
                .btn {
                    width: 100%; } }
            .form-group {
                margin-bottom: 22px; } }
        .footer_btn_group {
            display: flex;
            justify-content: space-around;
            width: 100%;
            position: absolute;
            bottom: -8%;
            .footer_btn {
                padding: 5px 40px 5px 40px;
                border-radius: 40px;
                background: transparent;
                border: 1px solid #989898;
                font: {
                    size: 20px;
                    family: "Roboto",sans-serif; }
                color: #989898;
                transition: color.45s, background.45s, box-shadow.45s;
                cursor: pointer;
                &:hover {
                    color: #961480;
                    background: white;
                    box-shadow: 0 8px 21px -6px black;
                    transition: color.45s, background.45s, box-shadow.45s; } } }

        // Popup_footer type Fail
        &.Fail {
            filter: grayscale(1); }
        &.Fail .Popup_Title {
            color: #B3B3B3; }
        &.Fail .Popup_Text {
            color: #B3B3B3; }
        &.BookMarchmakersService,&.AnyQuestions {
            //height: 630px
            overflow: auto; } }

    &.Pay,
    &.ScheduleComplimentaryCall,
    &.BookMarchmakersService,
    &.RequestCall {
        .checkbox-container {
            display: flex;
            justify-content: center;
            align-items: center;
            .checkbox-text {
                color: grey; }
            a {
                color: #e44360;
                font-weight: bold; }
            .checkbox {
                margin-right: 10px;
                &.active {
                    .checkbox-mark {
                        background-color: #e44360;
                        svg {
                            opacity: 1; } } } }
            .checkbox-mark {
                width: 16px;
                height: 16px;
                background-color: #c4c4c4;
                border-radius: 3px;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: background-color.45s ease-in-out;
                svg {
                    opacity: 0;
                    transition: opacity.45s ease-in-out; } } }

        .textarea_input {
            height: 70px!important; }
        .form-group-wrap {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            .form-group {
                width: calc(50% - 5px); } }

        .form-default-popup-1 {
            padding: 38px 38px 25px 38px; }
        .form-group {
            margin-bottom: 20px!important; } }

    &.Questionnaire {
        padding: 50px 30px 30px 30px;
        width: 320px;
        .Popup_Header {
            overflow-y: visible; }
        .Popup_Header_Questionnaire {
            display: flex;
            flex-direction: column; } }


    .Questionnaire_title {
        color: #7A6569;
        font-size: 24px;
        line-height: 29px;
        font-family: $p-b, sans-serif;
        text-align: center; }
    .Questionnaire_subtitle {
        color: #7A6569;
        font-size: 15px;
        line-height: 17px;
        font-family: $p-b, sans-serif;
        text-align: center; }
    .Questionnaire_text {
        margin-top: 20px;
        font-size: 16px;
        line-height: 19px;
        color: #7A6569;
        text-align: center; }
    .Questionnaire_btn {
        background: #E54360;
        display: block;
        //white-space: nowrap
        border-radius: 25px;
        padding: 8px 25px;
        font-size: 16px;
        line-height: 22px;
        color: #FFFFFF;
        font-family: $p-b, sans-serif;
        text-transform: uppercase;
        margin-top: 20px;
        margin-right: auto;
        margin-left: auto;
        margin-bottom: 35px;
        cursor: pointer; }
    .Questionnaire_link_wrap {
        height: 158px;
        width: 260px;
        border-radius: 15px;
        overflow: hidden;
        margin-bottom: 10px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative; }
    .Questionnaire_link {
        display: flex;
        position: relative;
        width: 100%;
        height: 100%;
        cursor: pointer; } }


@media (min-width: 785px) {
    .Popup {
        &.Support,&.RequestCall,&.ScheduleComplimentaryCall,&.BookMarchmakersService,&.AnyQuestions {
            width: 100%;
            .popup-title {
                font-size: 25px; }
            .popup-subTitle {
                font-size: 15px; } }
        &.BookMarchmakersService,&.AnyQuestions {
            height: auto; }

        &.Questionnaire {
            min-width: 610px;
            max-width: 65%;
            width: auto;
            .Questionnaire_col_left {
                width: 50%; }
            .Questionnaire_col_right {
                width: 50%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center; }
            .Popup_Header_Questionnaire {
                flex-direction: row; } }
        .Questionnaire_title {
            text-align: center; }
        .Questionnaire_text {
            text-align: left; }
        .Questionnaire_btn {
            margin-right: 0;
            margin-left: 0;
            margin-bottom: 0;
            white-space: normal;
            text-align: center; }
        .Questionnaire_subtitle {
            text-align: center; } } }


@media (min-width: 768px) {
    .Popup {
        &.Popup_footer {
            flex-wrap: nowrap;
            top: 78%;
            & .Popup_Text {
                font-size: 17px;
                text-align: center;
                padding-right: 0;
                padding-left: 10px;
                button {
                    font-size: 17px; } }
            .footer_btn_group {
                display: flex;
                justify-content: center;
                .footer_btn {
                    padding: 8px 25px 8px 25px;
                    border-radius: 40px;
                    background: #009933;
                    font: {
                        size: 17px; }
                    family: "Roboto",sans-serif;
                    color: white;
                    transition: color.45s, background.45s, box-shadow.45s;
                    cursor: pointer;
                    margin-left: 56px;
                    &:hover {
                        color: #961480;
                        background: white;
                        box-shadow: 0 8px 21px -6px black;
                        transition: color.45s, background.45s, box-shadow.45s; }
                    &:last-child {
                        margin-left: 13px; } } } } } }
@media screen and (min-width: 992px) {

    .Button_Close {
        //height: 1vw
        //width: 1vw
        width: 30px;
        height: 30px;
        transform: scale(1);
        transition: transform 300ms ease-in-out;
        cursor: pointer;
        &:hover {
            transform: scale(1.2);
            transition: transform 300ms ease-in-out; } }

    .Popup {
        &.Popup_footer {
            top: 85%;
            & .Popup_Title {
                font-size: 1.3vw;
                margin-bottom: 0.8vw; }
            & .Popup_Text {
                text-align: left; }
            .footer_btn_group {
                width: auto; } } } }

@media(min-width: 996px) {
    .Popup {
        .general-error {
            bottom: -29px;
            font-size: 13px; } }

    .Questionnaire_col_left {
        margin-right: 30px; } }
/* Notification Popup END */
@media (min-width: 1920px) {
    .Popup {

        &.Questionnaire {
            min-width: 610px;
            max-width: 40%; } } }

@media (min-width: 2500px) {
    .Popup {

        &.Questionnaire {
            min-width: 610px;
            max-width: 30%; } } }
