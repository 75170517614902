// Moved to _async_fonts
//+font_include(Pusia-Regular,Pusia-Regular,Pusia-Regular)
//+font_include(Pusia-Medium,Pusia-Medium,Pusia-Medium)
//+font_include(Pusia-Bold,Pusia-Bold,Pusia-Bold)

@import "resources/styles/sass/includes/spinner";

.Img_As_Background {
	position: absolute;
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: top; }

.grecaptcha-badge {
	right: -100%!important; }

.body_overflow {
	overflow: hidden;
	position: fixed;
	width: 100%;
	height: 100%; }

.fade-anim-enter {
	opacity: 0;
	transform: scale(0.9); }

.fade-anim-enter-active {
	opacity: 1;
	transform: translateX(0);
	transition: opacity 300ms, transform 300ms; }


.fade-anim-exit {
	opacity: 1; }

.fade-anim-exit-active {
	 opacity: 0;
	 transform: scale(0.9);
	 transition: opacity 300ms, transform 300ms; }
.req {
	color: #E54461; }
.dn {
	display: none; }

.upload-container {
	margin-left: 15px;
	margin-right: 15px;
	margin-bottom: 25px; }
.btn-red_full {
	width: 100%; }

.container-margin_15 {
	padding-left: 15px;
	padding-right: 15px; }
.container {
	max-width: 1130px;
	width: calc(100% - 20px);
	margin-right: auto;
	margin-left: auto;
	display: -webkit-box;
	display: flex;
	flex-wrap: wrap; }

.row_space-between {
	display: flex;
	width: 100%;
	justify-content: space-between;
	border-bottom: 1px solid #7A6569;
	margin-bottom: 20px;
	flex-direction: column; }

#Register-form .form-group {
	margin-left: 15px;
	margin-right: 15px;
	margin-bottom: 25px; }
.column-4 {
	width: 100%; }
.pos-rel_z-index_2 {
	position: relative;
	z-index: 2; }
.title-gen {
	font-family: $p-b,sans-serif;
	font-style: normal;
	font-size: 25px;
	line-height: 48px;
	&_7A6569 {
		color: #7A6569; } }
.sub-title-gen {
	font-size: 17px;
	line-height: 22px;
	font-family: $p-r, sans-serif;
	&_7A6569 {
		color: #7A6569; } }
.title-gen_flex {
	display: flex;
	align-items: center;
	justify-content: center; }
.title-desc-gen {
	font-family: $p-r, sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 15px;
	line-height: 21px;
	text-align: center;
	color: #FFFFFF; }
.title-gen_mgt {
	margin-top: 20px; }
.title-desc-gen_mgb {
	margin-bottom: 38px; }
.tile-gen_svg {
	width: 156px;
	height: 52px;
	margin-left: 7px; }
.container-block {
	max-width: 1130px;
	margin-right: auto;
	margin-left: auto; }
.container-hobbi {
	max-width: 840px;
	margin-right: auto;
	margin-left: auto; }

.text_center {
	text-align: center; }
.form-btn-wrap {
	padding-left: 10px;
	padding-right: 10px; }
.btn-love {
	position: relative;
	width: 88vw;
	height: 45px;
	background-color: #fff;
	color: #E54360;
	font-style: normal;
	font-weight: normal;
	font-size: 17px;
	line-height: 22px; }
.btn-svg-love {
	position: absolute;
	opacity: 0;
	visibility: hidden;
	transition: visibility 300ms ease-in-out, opacity 300ms ease-in-out;
	&:nth-child(1) {
		right: 20px;
		top: -5px;
		transform: translateY(3px);
		transition: transform 300ms ease-in-out; }
	&:nth-child(2) {
		right: 13px;
		transform: translateY(10px);
		transition: transform 500ms ease-in-out; } }

body {
	width: 100%; }
@import "./hours";
@media screen and (min-width: 768px) {
	.form-btn-wrap {
		padding-left: 0;
		padding-right: 0; }
	.row_space-between {
		flex-direction: row; }
	.column-4 {
		width: 33.3%; }
	.title-gen {
		font-size: 27px; }
	//.sub-title-gen
	//	font-size: 20px;
	.btn-love {
		width: 233px;
		&:hover {
			background-color: #fff;
			.btn-svg-love {
				opacity: 1;
				transform: translateY(-8px);
				visibility: visible;
				transition: all 300ms ease-in-out; } } } }
//@media screen and (min-width: 996px)
////
////	.title-gen
////		font-size: 40px;
////	//.sub-title-gen
////	//	font-size: 30px;


@import "../../../views/blocks/popup/popup";

@media screen and (min-width: 1800px) {
	.container-height-4k {
		min-height: calc(100vh - 271px); } }
.column.column-1,.column.column-2,.column.column-3,.column.column-4 {
	width: 100%; }
@media screen and (min-width: 800px) {
	.column.column-1 {
		width: 100%; }
	.column.column-2 {
		width: 50%; }
	.column.column-3 {
		width: 33%; }
	.column.column-4 {
		width: 25%; } }

.btn-go-to {
	font-family: Pusia-Regular,sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
	text-transform: uppercase;
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: #7a6569;
	background: transparent;
	border: none;
	outline: none;
	cursor: pointer;
	svg {
		margin-left: 6px; } }
.page-nav-bar {
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: flex-end; }

a {
	cursor: pointer; }

.align-items__end {
	align-items: flex-end; }

@import '~intl-tel-input/build/css/intlTelInput.css';

//.iti__country-list
//	background-color: #292a4e!important
//	border: 1px solid #212244!important
//	min-width: 245px
//
//.iti__divider
//	border-bottom: 1px solid #212244


.iti-mobile .iti--container {
	width: auto;
	z-index: 99999999; }
.iti {
	width: 100%;
	z-index: 999; }
.iti__country-list {
	white-space: normal!important;
	min-width: 255px;
	max-height: 160px!important;
	@media(min-width: 768px) {
		min-width: 278px; } }
.iti__selected-flag {
	border-top-left-radius: 30px;
	border-bottom-left-radius: 30px; }
.iti__flag {
	background-image: url("/img/flags/flags.png"); }

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
	.iti__flag {
		background-image: url("/img/flags/flags@2x.png"); } }

.input-phone-wrapper {
	input {
		width: 100%;
		height: 40px;
		border-radius: 30px;
		border: 1px solid #b3b3b3;
		font-size: 15px;
		font-family: Pusia-Regular,sans-serif;
		font-weight: 300;
		padding-left: 10px;
		padding-right: 10px;
		outline: none;
		-webkit-transition: border .3s;
		transition: border .3s; } }


.flex-break {
	flex-basis: 100%;
	height: 0; }

.Autf {
	.Reg_Link {
		&.flicker {
			//box-shadow: 0px 0px 9px 0px rgba(189, 86, 105, 0.2)
			transform: scale(1);
			animation-name: flicker;
			animation-iteration-count: 7;
			animation-timing-function: ease-in-out;
			animation-duration: 1s; } } }


@keyframes flicker {
	0% {
		transform: scale(1); }
	50% {
		transform: scale(.8); }
	100% {
		transform: scale(1); } }
